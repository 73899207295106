"use client"
import Maps from './maps';
import Link from 'next/link';
import '../../../globals.css';
import { joinTeamData } from './data';
import FadeUp from '@/lib/dataAos/fadeUp';
import FadeDown from '@/lib/dataAos/fadeDown';
import { useMediaQuery } from '@mui/material';
import FadeLeft from '@/lib/dataAos/fadeLeft';
import { getDictionary } from '@/dictionaries';
import FadeRight from '@/lib/dataAos/fadeRight';
import { useTheme } from '@mui/material/styles';
import MediaSocial from '../corosel/mediaSocial';
import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, Container } from '@mui/material';
import MotionRight from '@/lib/framerMotion/motionRight';
import WhileUp from '@/lib/framerMotion/whileUp';
import WhileDown from '@/lib/framerMotion/whileDown';
import WhileLeft from '@/lib/framerMotion/whileLeft';
import WhileRight from '@/lib/framerMotion/whileRight';

const Contact = ({ params }: { params: { lang: string } }) => {
  const [dictionary, setDictionary] = useState<{ [key: string]: string }>({});
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchDictionary = async () => {
      try {
        const result = await getDictionary(params.lang);
        setDictionary(result);
      } catch (error) {
        console.error("Error fetching dictionary:", error);
      }
    };
    fetchDictionary();
  }, [params.lang]);

  return (
    <Box sx={{ background: '#333333', height: 'auto', overflow: 'hidden' }}>
      <Box sx={{ paddingTop: '100px ', flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item lg={5} xs={12} order={isSmallScreen ? 2 : 1} sx={{ marginTop: isSmallScreen ? '60px' : 0 }}>
            <WhileDown duration={0.9}>
              <Box className="imgHandShake">
                <img src="/img/footerImg.jpg" alt="" style={{ objectFit: 'cover', height: '100%', width: '100%' }} />
              </Box>
            </WhileDown>
            {/* <FadeDown duration='1000'>
            </FadeDown>
            <FadeUp duration='1000'>
            </FadeUp> */}
            <WhileUp duration={0.9}>
              <Box className='imgGrid'>
                <img src="/img/Grid.png" alt="" />
              </Box>
            </WhileUp>
          </Grid>
          <Grid item lg={7} xs={12} order={isSmallScreen ? 1 : 2}>
            <Grid item lg={9}
              sx={{
                marginTop: '20px'
              }}
            >
              <Box sx={{ padding: '0 10px' }}>
                <WhileUp duration={0.9}>
                  <Typography className='titleContact'>
                    {dictionary.titleContact}
                  </Typography>
                </WhileUp>
                {/* <FadeUp duration='1000'>
                </FadeUp> */}
                <Box sx={{ marginTop: '50px' }}>
                  <WhileUp duration={0.9}>
                    <Link href={'https://wa.me/6281932576394'} target="_blank">
                      <Button variant='contained' className='buttonContact'>
                        {dictionary.buttonContact}
                      </Button>
                    </Link>
                  </WhileUp>
                  {/* <FadeUp duration='1000'>
                  </FadeUp> */}
                </Box>
              </Box>
              <Box className="joinTeamContainerDestop">
                <WhileUp duration={0.9}>
                  <Typography
                    sx={{
                      fontSize: '36px',
                      fontWeight: 400,
                      lineHeight: '40px',
                      color: '#fff'
                    }}
                    className="flexoRegular"
                  >
                    {dictionary.inviteTeam}
                  </Typography>
                </WhileUp>
                {/* <FadeUp duration='1000'>
                </FadeUp> */}
                <Box className='joinTeamBox'>
                  {joinTeamData?.map((item) => (
                    <Box key={item.id}>
                      <WhileUp duration={0.9}>
                        <a href={item.link} className='joinTeam' target="_blank">
                          <Typography className="flexoRegular">
                            {item.name}
                          </Typography>
                          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.47 2C1.47 1.30964 2.02964 0.75 2.72 0.75H20C20.6904 0.75 21.25 1.30964 21.25 2V19.28C21.25 19.9704 20.6904 20.53 20 20.53C19.3096 20.53 18.75 19.9704 18.75 19.28V5.01777L2.88388 20.8839C2.39573 21.372 1.60427 21.372 1.11612 20.8839C0.627961 20.3957 0.627961 19.6043 1.11612 19.1161L16.9822 3.25H2.72C2.02964 3.25 1.47 2.69036 1.47 2Z" fill="#C1EBFB" />
                          </svg>
                        </a>
                      </WhileUp>
                      {/* <FadeUp duration='1000'>
                      </FadeUp> */}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            order={3}
            sx={{
              display: isSmallScreen ? 'Block' : 'none',
              margin: '50px 10px 0 10px',

            }}
          >
            <WhileUp duration={0.9}>
              <Typography
                sx={{
                  fontSize: '36px',
                  fontWeight: 400,
                  lineHeight: '40px',
                  color: '#fff'
                }}
                className="flexoRegular"
              >
                {dictionary.inviteTeam}
              </Typography>
            </WhileUp>
            {/* <FadeUp duration='1000'>
            </FadeUp> */}
            <Box className='joinTeamBox'>
              {joinTeamData?.map((item) => (
                <Box key={item.id}>
                  <WhileUp duration={0.9}>
                    <a href={item.link} className='joinTeam' target="_blank">
                      <Typography className="flexoRegular">
                        {item.name}
                      </Typography>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M1.47 2C1.47 1.30964 2.02964 0.75 2.72 0.75H20C20.6904 0.75 21.25 1.30964 21.25 2V19.28C21.25 19.9704 20.6904 20.53 20 20.53C19.3096 20.53 18.75 19.9704 18.75 19.28V5.01777L2.88388 20.8839C2.39573 21.372 1.60427 21.372 1.11612 20.8839C0.627961 20.3957 0.627961 19.6043 1.11612 19.1161L16.9822 3.25H2.72C2.02964 3.25 1.47 2.69036 1.47 2Z" fill="#C1EBFB" />
                      </svg>
                    </a>
                  </WhileUp>
                  {/* <FadeUp duration='1000'>
                  </FadeUp> */}
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={10}>
          <Grid item lg={5}>
            <WhileRight duration={0.9}>
              <Box className='imgEmkayBuilding'>
                <img
                  src="/img/kantor-emkay.jpg"
                  style={{
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                    objectPosition: '50% 70%'
                  }}
                  alt=""
                />
              </Box>
            </WhileRight>
            {/* <FadeRight duration='700'>
            </FadeRight> */}
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '64px'
              }}
            >
              <Box
                sx={{
                  width: isSmallScreen ? '80%' : '384px',
                  padding: isSmallScreen ? '0 60px 0 20px' : 0
                }}
              >
                <WhileRight duration={0.9}>
                  <Typography className="iea">
                    PT Indo Emkay Abadi
                  </Typography>
                </WhileRight>
                {/* <FadeRight duration='700'>
                </FadeRight>
                <FadeRight duration='700'>
                </FadeRight> */}
                <WhileRight duration={0.9}>
                  <Typography className="ieaAddress">
                    Pergudangan Taman Tekno BSD, Blok H7 No.1, Setu, Kec. Setu, Kota Tangerang Selatan, Banten 15314
                  </Typography>
                </WhileRight>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={7} xs={12} sx={{ margin: isSmallScreen ? '30px 0' : '0', }}>
            <WhileLeft duration={0.9}>
              <Box className='imgMapLocation'>
                <Maps />
              </Box>
            </WhileLeft>
            {/* <FadeLeft duration='700'>
            </FadeLeft> */}
          </Grid>
        </Grid>
      </Box>
      <Container maxWidth="lg" >
        <Grid container spacing={2} sx={{ paddingTop: '75px', paddingBottom: '25px' }}>
          <Grid item xs={12} lg={8}>
            <MotionRight duration={1.6}>
              <Typography
                className="flexoRegular"
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  color: '#E6E6E6'
                }}
              >
                © EMKAY | PT. Indo Emkay Abadi. {dictionary.footerText1}
              </Typography>
            </MotionRight>
          </Grid>
          <Grid item xs={12} lg={4}
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography
              className="flexoRegular"
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                color: '#E6E6E6',
                marginRight: '10px'
              }}
            >
              {dictionary.footerText2}
            </Typography>
            <MediaSocial />
          </Grid>
        </Grid>
      </Container>
    </Box >
  )
}

export default Contact
