import React from 'react'
import { LoadScript, GoogleMap, Marker } from '@react-google-maps/api'
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const Maps = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const center = {
    lat: -6.3307331,
    lng: 106.6787498
  }
  const style = {
    width: '100%',
    height: isSmallScreen ? '40vh' : '90vh'
  }


  return (
    <div style={{ width: '100%' }}>
      <LoadScript googleMapsApiKey={process.env.NEXT_PUBLIC_GOOGLE_API_KEY ? process.env.NEXT_PUBLIC_GOOGLE_API_KEY : ''} mapIds={['2b95bf2655899320']}>
        <GoogleMap
          mapContainerStyle={style}
          center={center}
          zoom={16}
          options={{ mapId: '2b95bf2655899320' }}
        >
          <Marker position={center} icon={{ url: '/img/marker.png', scaledSize: { width: 45, height: 55 } as any }} />
        </GoogleMap>
      </LoadScript>
    </div>
  )
}

export default Maps
