export const joinTeamData =[
  {
    id: '1',
    name: 'Linkedin',
    link: 'https://www.linkedin.com/company/indo-emkay-abadi/jobs/'
  },
  // {
  //   id: '2',
  //   name: 'Finance',
  //   link: '#'
  // },
  // {
  //   id: '3',
  //   name: 'Director R&D',
  //   link: '#'
  // }
]