"use client"
import '../../../globals.css';
import FadeUp from '@/lib/dataAos/fadeUp';
import FadeLeft from '@/lib/dataAos/fadeLeft';
import { getDictionary } from '@/dictionaries';
import FadeRight from '@/lib/dataAos/fadeRight';
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';

const Page = ({ params }: { params: { lang: string } }) => {
  const [dictionary, setDictionary] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchDictionary = async () => {
      try {
        const result = await getDictionary(params.lang);
        setDictionary(result);
      } catch (error) {
        console.error("Error fetching dictionary:", error);
      }
    };
    fetchDictionary();
  }, [params.lang]);
  return (
    <div style={{ margin: "50px 0 100px 0" }}>
      <Box sx={{ position: 'absolute', zIndex: 4, width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <FadeRight duration='700'>
            <Box className="imgShopKing">
              <img src="/img/blastLiteLechy.png" alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            </Box>
          </FadeRight>
          <FadeLeft duration='700'>
            <Box className="" style={{ overflow: 'hidden' }}>
              <Box className="imgShopDl">
                <img src="/img/DARKLUNA.png" alt="" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
              </Box>
            </Box>
          </FadeLeft>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '-280px' }}>
          <FadeRight duration='700'>
            <Box className="imgShopBanli">
              <img src="/img/banli.png" alt="" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
            </Box>
          </FadeRight>
          <FadeLeft duration='700'>
            <Box sx={{ marginTop: '200px' }}>
              <Box className="imgShopSundae">
                <img src="/img/blastMenthol.png" alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              </Box>
            </Box>
          </FadeLeft>
        </Box>
      </Box>
      <Box sx={{ position: 'relative', zIndex: 5, height: 'auto' }}>
        <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '150px 0' }}>
          <Grid item xs={12} lg={6} sx={{ textAlign: 'center', height: 'auto' }} >
            <FadeUp duration='700'>
              <Typography className="titleShop">
                {dictionary.titleShop}
              </Typography>
            </FadeUp>
            <FadeUp duration='700'>
              <Box mt={3} sx={{ display: 'flex' }}>
                {params.lang === 'id' ? (
                  <Typography className='subTitleShop'>
                    <span style={{ color: '#1A1A1A' }}>Siap untuk tingkatkan</span>{' '}
                    <span style={{ color: '#18576C' }}>pengalaman  vapingmu</span>
                    <span style={{ color: '#1A1A1A' }}>?</span>{' '}
                  </Typography>
                ) : (
                  <Typography className='subTitleShop'>
                    <span style={{ color: '#1A1A1A' }}>Ready to improve</span>{' '}
                    <span style={{ color: '#18576C' }}>your vaping experience</span>
                    <span style={{ color: '#1A1A1A' }}>?</span>{' '}
                  </Typography>
                )}
              </Box>
            </FadeUp>
            <FadeUp duration='700'>
              <Typography className="flexoRegular" sx={{ margin: '40px 0 80px 0' }}>
                {dictionary.contentShop}
              </Typography>
            </FadeUp>
            <FadeUp duration='700'>
              <a href="https://shop.emkay.id/"
                target="_blank"
                style={{
                  padding: '16px 28px',
                  color: '#fff',
                  backgroundColor: '#18576C',
                  borderRadius: '30px',
                  margin: '20px 0',
                  textDecoration: 'none'
                }}>
                {dictionary.buttonShop}
              </a>
            </FadeUp>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default Page
