"use client"
import './../globals.css';
import Link from 'next/link';
import Image from 'next/image';
import Shop from './section/shop/page';
import About from './section/about/page';
import createCache from '@emotion/cache';
import Award from './section/awards/page';
import Corosel from './section/corosel/page';
import { CacheProvider } from '@emotion/react';
import { getDictionary } from '@/dictionaries';
import { Container, Box } from '@mui/material';
import Contact from './section/contact/contact';
import Production from './section/production/page';
import Hamburger from './section/navBar/hamburger';
import MotionDown from '@/lib/framerMotion/motionDown';
import useMediaQuery from '@mui/material/useMediaQuery';
import MotionRight from '@/lib/framerMotion/motionRight';
import React, { useEffect, useState, ChangeEvent } from 'react';
import CheckboxDictionary from '@/components/checkboxDictionaries/page';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import ChatBotComponent from '@/components/modal/chatBotComponent';

const Page = ({ params }: { params: { lang: string } }) => {
  const [dictionary, setDictionary] = useState<{ [key: string]: string }>({});
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const isLargeScreen = useMediaQuery('(min-width:1600px)');
  const isMobileScreen = useMediaQuery('(max-width:600px)');
  const [isContactSection, setIsContactSection] = useState(false);
  const cache = createCache({
    key: 'css',
    prepend: true,
  });

  const sectionIds = ['about', 'production', 'shop', 'award', 'contact'];
  const [activeSection, setActiveSection] = useState<string | null>(null);

  const handleScroll = () => {
    const scrollY = window.scrollY;

    for (const sectionId of sectionIds) {
      const section = document.getElementById(sectionId);
      if (section) {
        const sectionTop = section.offsetTop;
        const sectionBottom = sectionTop + section.clientHeight;

        if (scrollY >= sectionTop && scrollY <= sectionBottom) {
          setActiveSection(sectionId);
          setIsContactSection(sectionId === 'contact');
          setIsScrolled(scrollY > 0);
          return;
        }
      }
    }

    setActiveSection(null);
    setIsContactSection(false);
    setIsScrolled(scrollY > 0);
  }

  useEffect(() => {
    const fetchDictionary = async () => {
      try {
        const result = await getDictionary(params.lang);
        setDictionary(result);
      } catch (error) {
        console.error("Error fetching dictionary:", error);
      }
    };
    fetchDictionary();
  }, [params.lang]);

  const handleScrollW = () => {
    const scrollY = window.scrollY;

    const sections = document.querySelectorAll<HTMLDivElement>('[id^="contact"]');
    let foundContactSection = false;

    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      const sectionBottom = sectionTop + section.clientHeight;

      if (scrollY >= sectionTop && scrollY <= sectionBottom) {
        foundContactSection = true;
      }
    });
    setIsContactSection(foundContactSection);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollW);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScrollW);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const header = document.querySelector('.header');
    if (header) {
      if (scrollPosition > 0) {
        header.classList.add('scrolledHeader');
      } else {
        header.classList.remove('scrolledHeader');
      }
    }
  }, [scrollPosition]);

  return (
    <div>
      <CacheProvider value={cache}>
        <ChatBotComponent />
        <Box sx={{ display: isScrolled ? 'block' : 'none', transition: 'display 2.5 ease' }}>
          <Box className="boxTriger" >
            <Box className={isContactSection ? 'hidden' : 'visible'}>
              <Box className="whatsappTriger">
                <Link href={'https://wa.me/6281932576394'} target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_633_1926)">
                      <path fillRule="evenodd" clipRule="evenodd" d="M12.0003 0.650392C18.1946 0.646871 23.3608 5.82207 23.3608 12.0044C23.3608 18.1944 18.1951 23.3541 12 23.3499C10.3861 23.3489 8.7701 23.0088 7.28026 22.3279L1.65616 23.3448C1.35258 23.3997 1.04293 23.2872 0.845352 23.0503C0.647775 22.8134 0.59276 22.4885 0.701299 22.1998L2.31094 17.9172C1.22728 16.1395 0.649772 14.0844 0.649762 12.0044C0.649733 5.81904 5.80971 0.652456 12.0003 0.650392ZM17.6997 16.8272C16.4358 17.8495 14.4806 18.881 12.6109 17.6808C10.1585 16.1065 7.98833 14.036 6.41388 11.5838C5.49084 10.1461 6.35948 7.84527 7.659 6.44326C8.17781 5.88351 9.03098 5.97202 9.59461 6.48658L10.7812 7.56989C11.1421 7.89938 11.1548 8.47205 10.9298 8.90585C10.575 9.58987 10.3664 10.507 10.8051 11.1904C11.3562 12.049 12.0364 12.8935 13.6569 13.3867C14.1471 13.5358 14.7773 13.4715 15.3113 13.2552C15.7598 13.0735 16.3269 13.0756 16.6582 13.4283L17.7612 14.6025C18.3695 15.2501 18.3905 16.2684 17.6997 16.8272Z" fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_633_1926">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Link>
              </Box>
            </Box>
            <Box className="upArrowTriger">
              <ScrollLink to="hero" smooth={true} duration={1800}>
                <img src={'/img/arrowUp.png'} alt="Up Arrow Icon" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              </ScrollLink>
            </Box>
          </Box>
        </Box>
        <Box sx={{ position: 'relative', zIndex: 5 }}>
          <Box sx={{ display: isMobileScreen ? 'none' : 'block' }}>
            <Box sx={{ width: '100%', zIndex: 'tooltip', position: 'fixed', display: 'flex', justifyContent: 'center' }}>
              <Container maxWidth={isLargeScreen ? 'xl' : 'lg'} >
                <Box className={`header ${scrollPosition > 0 ? 'scrolledHeader' : ''}`}>
                  <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 0 0 15px' }}>
                    <MotionRight duration={1.4}>
                      <ScrollLink to="hero" smooth={true} duration={1800}>
                        <Box className="imgLogoContainer">
                          <img src='/img/Logo-white-new-01.png' style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                        </Box>
                      </ScrollLink>
                    </MotionRight>
                  </Box>
                  <Box className="menuHeader">
                    <MotionDown duration={1.3}>
                      <ScrollLink to="about" smooth={true} duration={1800} className="listMenuHeader" style={{ color: activeSection === 'about' ? '#999999' : '#fff' }}>
                        {dictionary.menuList1}
                      </ScrollLink>
                    </MotionDown>
                    <MotionDown duration={1.4}>
                      <ScrollLink to="production" smooth={true} duration={1800} className="listMenuHeader" style={{ color: activeSection === 'production' ? '#999999' : '#fff' }}>
                        {dictionary.menuList2}
                      </ScrollLink>
                    </MotionDown>
                    <MotionDown duration={1.5}>
                      <ScrollLink to="shop" smooth={true} duration={1800} className="listMenuHeader" style={{ color: activeSection === 'shop' ? '#999999' : '#fff' }}>
                        {dictionary.menuList3}
                      </ScrollLink>
                    </MotionDown>
                    <MotionDown duration={1.6}>
                      <ScrollLink to="award" smooth={true} duration={1800} className="listMenuHeader" style={{ color: activeSection === 'award' ? '#999999' : '#fff' }}>
                        {dictionary.menuList4}
                      </ScrollLink>
                    </MotionDown>
                    <MotionDown duration={1.6}>
                      <Box sx={{ width: '60px', height: '30px', overflow: 'hidden', marginRight: '10px' }}>
                        <CheckboxDictionary />
                      </Box>
                    </MotionDown>
                    <ScrollLink to="contact" smooth={true} duration={1800} className="buttonHeader">
                      {dictionary.menuList5}
                    </ScrollLink>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Box>
          <Box sx={{ display: isMobileScreen ? 'block' : 'none' }}>
            <Container maxWidth='xl' sx={{ zIndex: 'tooltip', position: 'fixed', }} >
              <Box className={`header ${scrollPosition > 0 ? 'scrolledHeader' : ''}`}>
                <ScrollLink to="hero" style={{ display: 'flex', alignItems: 'center' }} smooth={true} duration={1800}>
                  <MotionRight duration={1.4}>
                    <Box className="imgLogoContainer">
                      <img src='/img/Logo-white-new-01.png' style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                    </Box>
                  </MotionRight>
                </ScrollLink>
                <MotionDown duration={1.4}>
                  <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '5px', overflow: 'hidden' }}>
                    <Hamburger params={{ lang: params.lang }} />
                  </Box>
                </MotionDown>
              </Box>
            </Container>
          </Box>
          <Box id="hero" sx={{ zIndex: 'modal' }}>
            <Corosel params={{ lang: params.lang }} />
          </Box>
          <Box id="about" >
            <About params={{ lang: params.lang }} />
          </Box>
          <Box id='production'>
            <Production params={{ lang: params.lang }} />
          </Box>
          <Box id="shop">
            <Shop params={{ lang: params.lang }} />
          </Box>
          <Box id="award">
            <Award params={{ lang: params.lang }} />
          </Box>
          <Box id="contact">
            <Contact params={{ lang: params.lang }} />
          </Box>
        </Box>
      </CacheProvider>
    </div >
  )
}

export default Page
