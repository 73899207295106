'use client'
import customColors from '@/lib/colors'
import { useMediaQuery } from '@mui/material'
import { BubbleChat } from 'flowise-embed-react'
import React from 'react'

const ChatBotComponent = () => {
    const mobile = useMediaQuery('(max-width:600px)');
    const extraLarge = useMediaQuery('(min-width:1800px)');
    const width = typeof window !== 'undefined' ? window.innerWidth : 350;
    const height = typeof window !== 'undefined' ? window.innerHeight : 600;

    return (
        <BubbleChat chatflowid="6402e5c7-9ebc-40c7-b1d1-30444cbb1b57" apiHost="https://ai.emkay.id"
            theme={{
                button: {
                    backgroundColor: customColors.primaryEmkay,
                    right: 20,
                    bottom: 20,
                    size: "large",
                    iconColor: "white",
                    // customIconSrc: "https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg",
                },
                chatWindow: {
                    title: 'Emkay Assistant',
                    welcomeMessage: "Hi there! How can I help?",
                    backgroundColor: "#ffffff",
                    height: mobile ? height * 80 / 100 : extraLarge ? 700 : 650,
                    width: mobile ? width : 400,
                    fontSize: 16,
                    poweredByTextColor: "white",
                    botMessage: {
                        backgroundColor: "#f7f8ff",
                        textColor: "#303235",
                        showAvatar: false,
                        avatarSrc: "https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/parroticon.png",
                    },
                    userMessage: {
                        backgroundColor: customColors.primaryEmkay,
                        textColor: "#ffffff",
                        showAvatar: false,
                        avatarSrc: "https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png",
                    },
                    textInput: {
                        placeholder: "Type your question",
                        backgroundColor: "#ffffff",
                        textColor: "#303235",
                        sendButtonColor: customColors.primaryEmkay,
                    }
                }
            }}
        />
    )
}

export default ChatBotComponent

