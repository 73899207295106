import { Box } from '@mui/material';
import { useRouter, usePathname } from 'next/navigation';
import React, { ChangeEvent, useState, useEffect } from 'react';
import '@/app/globals.css';

const CheckboxDictionary: React.FC = () => {
  const router = useRouter();
  const pathname = usePathname();
  const [isChecked, setIsChecked] = useState(pathname === '/en');
  const [content, setContent] = useState(isChecked ? 'EN' : 'ID');
  const spanContent = content === 'EN' ? 'ID' : 'EN';

  useEffect(() => {
    setContent(isChecked ? 'EN' : 'ID');
  }, [isChecked]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checkbox = event.target;
    const newLang = checkbox.checked ? 'en' : 'id';
    setIsChecked(checkbox.checked);
    setContent(checkbox.checked ? 'EN' : 'ID');
    router.push(`/${newLang}`);
  };

  return (
    <Box sx={{ width: '600px' }}>
      <input
        type="checkbox"
        className={`${'checkboxDictionary'} ${isChecked ? 'checked' : ''}`}
        data-content={content}
        onChange={handleChange}
      />
      <span className='checkboxLabel' style={{ left: isChecked ? '10px' : '38px' }} >{spanContent}</span>
    </Box>
  );
};

export default CheckboxDictionary;